import styled from "styled-components";
import { Box, SpanStyled } from "../Styled/Elements";
import { useTranslation } from "react-i18next";

export const PaymentBadge = ({
  amount = "100.00",
  currency = "AED",
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <BadgeWrapper>
      <Box className="">
        <SpanStyled size="13px" className="text text-wrap">
          {t("four_interest_free_payment")}
          &nbsp;
          <strong>
            {amount} {t(currency)}
          </strong>
          &nbsp;
          <span className="learn-more">
            {t("learn_more")}
          </span>
        </SpanStyled>
      </Box>
      <Box className="d-flex gap-1 align-items-center">
        <TabbyButton>
          <span className="tabby-text">tabby</span>
        </TabbyButton>
      </Box>
    </BadgeWrapper>
  );
};

const BadgeWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid #646464;
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  font-family: Arial, sans-serif;
  max-width: 285px;
  color: #333;

  strong {
    font-weight: bold;
  }
  .learn-more {
    text-decoration: underline;
    font-size: 12px;
  }
  .learn-more:hover {
    text-decoration: underline;
  }
`;

export const TabbyButton = styled.button`
  background: rgb(59, 251, 173);
  border: none;
  border-radius: 5px;
  padding: 4px 8px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;

  &:hover {
    background: rgba(59, 251, 173, .6);
  }
  .tabby-text {
    color: #000;
  }
`;
