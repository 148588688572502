import i18n from "./Languages/INEXT";
import React, { useEffect } from "react";
import { Box } from "./components/Styled/Elements";
import { privateRoute } from "./route/PrivateRoute";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { scroller, animateScroll as scroll, Events } from "react-scroll";
import { socialIcon } from "./components/data/Data";
import { ToastContainer } from "react-toastify";
import "swiper/css";
import { useTranslation } from "react-i18next";

function App() {
  const {
    i18n: { language },
  } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const removeQueryParam = () => {
    let section = searchParams.get("section");
    if (section) {
      section = section.toLowerCase();
      const element = document.getElementById(section);
      if (element) {
        scroller.scrollTo(section, {
          duration: 500,
          smooth: true,
        });
      }
    }
  };
  useEffect(() => {
    removeQueryParam();
  }, [location]);

  useEffect(() => {
    Events.scrollEvent.register("begin", (to, element) => {
      if (searchParams.has("section")) {
        searchParams.delete("section");
        const newUrl = location.pathname + searchParams.toString();
        navigate(newUrl);
      }
    });

    return () => {
      Events.scrollEvent.remove("begin");
    };
  });

  return (
    <>
      <Box className="main-artical">
        <Routes>
          {privateRoute.map((list, key) => (
            <Route
              key={key}
              {...list}
              element={<list.element removeQuery={removeQueryParam} />}
            >
              {list.children &&
                list.children.map((childRoute, i) => (
                  <Route
                    key={i}
                    {...childRoute}
                    element={<childRoute.element />}
                  />
                ))}
            </Route>
          ))}
        </Routes>
        {/* <Box className="social-link">
          {socialIcon.map((list, key) => (
            <Box key={key} className="contact-links">
              <a href={list.link} target="_blank" rel="noopener noreferrer">
                <Box className="social-icon">{list.img}</Box>
              </a>
            </Box>
          ))}
        </Box> */}
      </Box>
      <ToastContainer
        position={language === "ar" ? "top-left" : "top-right"}
        rtl={language === "ar"}
      />
    </>
  );
}

export default App;
