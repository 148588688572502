import React, { useEffect, useState } from "react";
import { PaymentStatusStyled } from "./styled";
import {
  ArrowRight2,
  FailedIcon,
  PagesBg,
  Phone,
  SuccessIcon,
} from "../../components/Styled/AllImages";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import {
  Box,
  HeadingStyled,
  SubHeadingStyled,
  TextStyled,
} from "../../components/Styled/Elements";
import {
  ButtonWithIcon,
  ContactButton,
} from "../../components/Styled/CustomElements";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useCheckStatusMutation,
  useCheckTabbyStatusMutation,
  useCreateSessionMutation,
  useDownloadFileMutation,
} from "../../store/services/checkoutApi";
import axios from "axios";

const PaymentStatus = () => {
  const PACKAGE_ASSET_URL = process.env.REACT_APP_PACKAGE_ASSET_URL;
  const [orderData, setOrderData] = useState({});
  const [isWaiting, setIsWaiting] = useState(false);

  const { status } = useParams();
  const [searchParams] = useSearchParams();
  const paymentId = searchParams.get("payment_id");
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [checkStatus, { data, isLoading, isSuccess, isError, error }] =
    useCheckStatusMutation();
  const [
    checkTabbyStatus,
    { data: tabbyData, isLoading: isTabbyLoading, isTabbySuccess },
  ] = useCheckTabbyStatusMutation();
  const [
    trigger,
    {
      data: checkoutData,
      isLoading: isCheckoutLoading,
      isFetching: isCheckoutFetching,
      isError: isCheckoutError,
      refetch: refetchCheckout,
    },
  ] = useCreateSessionMutation();
  const [
    triggerDownload,
    {
      data: downloadData,
      isLoading: isFileLoading,
      isFetching: isFileFetching,
      isError: isFileError,
      refetch,
    },
  ] = useDownloadFileMutation();

  const WhatsApp = (num) => {
    const phoneNumber = `+${num}`;
    const message = "Hello, Own Your Health";
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  const handleAttachment = async () => {
    if (orderData) {
      const { data } = await triggerDownload({
        lang: language,
        fileName: orderData?.package?.plan,
      });

      const blobURL = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = blobURL;
      a.download = orderData?.package?.plan;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(blobURL);
      document.body.removeChild(a);
    }
    // if (orderData) {
    //   // const url = `${PACKAGE_ASSET_URL}/${orderData?.package?.plan}`;
    //   const url = `${PACKAGE_ASSET_URL}/172675718766ec394319773.pdf`;

    //   fetch(url)
    //     .then((response) => response.blob())
    //     .then((blob) => {
    //       const blobURL = window.URL.createObjectURL(blob);
    //       const a = document.createElement("a");
    //       a.style.display = "none";
    //       a.href = blobURL;
    //       a.download = orderData?.package?.plan;
    //       document.body.appendChild(a);
    //       a.click();
    //       window.URL.revokeObjectURL(blobURL);
    //       document.body.removeChild(a);
    //     })
    //     .catch((error) => console.error("Error downloading PDF:", error));
    // }
  };

  const tryCheckout = async () => {
    const orderData = localStorage.getItem("order_data");
    try {
      const { data, error } = await trigger({
        lang: language,
        data: JSON.parse(orderData),
      });

      if (error) {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      } else if (data) {
        localStorage.setItem(
          "order_info",
          JSON.stringify({
            telr_order_reference: data?.data?.telr_response?.ref,
            order_id: data?.data?.order.order_id,
          })
        );

        const checkoutUrl = data?.data?.telr_response?.url;
        if (checkoutUrl) {
          localStorage.setItem("order_data", orderData);
          window.location.href = checkoutUrl;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (status === "success" && localStorage.getItem("order_data")) {
      localStorage.removeItem("order_data");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("order_info")) {
      const orderInfo = JSON.parse(localStorage.getItem("order_info"));
      // if (paymentId) {
      //   checkTabbyStatus({
      //     lang: language,
      //     data: {
      //       payment_id: paymentId,
      //       ...orderInfo,
      //     },
      //   });
      // }
      if (status === "success" && paymentId) {
        setIsWaiting(true); // Set waiting state
        const timer = setTimeout(() => {
          checkTabbyStatus({
            lang: language,
            data: {
              payment_id: paymentId,
              ...orderInfo,
            },
          });
          setIsWaiting(false); // Remove waiting state after API call
        }, 10000); // 10 seconds delay

        return () => clearTimeout(timer); // Cleanup in case component unmounts
      } else {
        checkStatus({ lang: language, data: orderInfo });
      }
    }
  }, []);
  useEffect(() => {
    if (paymentId && tabbyData) {
      setOrderData(tabbyData?.data);
      localStorage.removeItem("order_info");
    }
    if (data) {
      setOrderData(data?.data);
      localStorage.removeItem("order_info");
    }
  }, [data, tabbyData]);
  return (
    <PaymentStatusStyled
      className="innerPagesBg"
      // style={{ backgroundImage: `url(${PagesBg})` }}
    >
      <Container className="common-container">
        {isLoading || isTabbyLoading || isWaiting ? (
          <Box className="d-flex justify-content-center">
            <Box className="d-flex flex-column align-items-center">
              <Spinner animation="border" className="custom-spinner" />
              <TextStyled size="18px" className="mt-3 text-center">
                {t("please_wait_payment_processing")}
              </TextStyled>
            </Box>
          </Box>
        ) : (
          <Box className="d-flex justify-content-center">
            <Box>
              <Box className="d-flex align-items-center justify-content-center">
                {status === "success" ? (
                  <>
                    <SuccessIcon className="mx-3" />
                    <HeadingStyled size="36px" color="var(--darkColor)">
                      {t("payment_successful")}
                    </HeadingStyled>
                  </>
                ) : status === "declined" ? (
                  <>
                    <FailedIcon className="mx-3" />
                    <HeadingStyled size="36px" color="var(--dangerColor)">
                      {t("payment_declined")}
                    </HeadingStyled>
                  </>
                ) : (
                  <>
                    <FailedIcon className="mx-3" />
                    <HeadingStyled size="36px" color="var(--dangerColor)">
                      {t("payment_failed")}
                    </HeadingStyled>
                  </>
                )}
              </Box>
              <Box>
                <SubHeadingStyled
                  size="20px"
                  weight="400"
                  color="var(--themeColor)"
                  className="my-3"
                >
                  {status === "success"
                    ? t("payment_received", { price: orderData?.grand_total })
                    : t("payment_error")}
                </SubHeadingStyled>
              </Box>
              <Box className="payment-info-card mt-5 p-5">
                <Row className="flex-nowrap">
                  <Col
                    lg={5}
                    md={6}
                    sm={6}
                    className="flex-shrink-1 custom-col"
                  >
                    {orderData?.package_name && (
                      <SubHeadingStyled
                        size="16px"
                        weight="500"
                        className="mb-2"
                        color="var(--white)"
                      >
                        {t("package_name")}
                      </SubHeadingStyled>
                    )}
                  </Col>
                  <Col lg={7} md={6} sm={6} className="custom-col">
                    {orderData?.package_name && (
                      <TextStyled
                        size="16px"
                        className="mb-2"
                        color="var(--white)"
                      >
                        {orderData?.package_name}
                      </TextStyled>
                    )}
                  </Col>
                </Row>
                <Row className="flex-nowrap">
                  <Col
                    lg={5}
                    md={6}
                    sm={6}
                    className="flex-shrink-1 custom-col"
                  >
                    {orderData?.product_name && (
                      <SubHeadingStyled
                        size="16px"
                        weight="500"
                        className="mb-2"
                        color="var(--white)"
                      >
                        {t("product_name")}
                      </SubHeadingStyled>
                    )}
                  </Col>
                  <Col lg={7} md={6} sm={6} className="custom-col">
                    {orderData?.product_name && (
                      <TextStyled
                        size="16px"
                        className="mb-2"
                        color="var(--white)"
                      >
                        {orderData?.product_name}
                      </TextStyled>
                    )}
                  </Col>
                </Row>
                <Row className="flex-nowrap">
                  <Col
                    lg={5}
                    md={6}
                    sm={6}
                    className="flex-shrink-1 custom-col"
                  >
                    <SubHeadingStyled
                      size="16px"
                      weight="500"
                      className="mb-2"
                      color="var(--white)"
                    >
                      {t("tax")}
                    </SubHeadingStyled>
                  </Col>
                  <Col lg={7} md={6} sm={6} className="custom-col">
                    <TextStyled
                      size="16px"
                      className="mb-2"
                      color="var(--white)"
                    >
                      {orderData?.vat_percentage} %
                    </TextStyled>
                  </Col>
                </Row>
                <Row className="flex-nowrap">
                  <Col
                    lg={5}
                    md={6}
                    sm={6}
                    className="flex-shrink-1 custom-col"
                  >
                    <SubHeadingStyled
                      size="16px"
                      weight="500"
                      className="mb-2"
                      color="var(--white)"
                    >
                      {t("total_amount")}
                    </SubHeadingStyled>
                  </Col>
                  <Col lg={7} md={6} sm={6} className="custom-col">
                    <TextStyled
                      size="16px"
                      className="mb-2"
                      color="var(--white)"
                    >
                      {orderData?.grand_total} {t("sar")}
                    </TextStyled>
                  </Col>
                </Row>
                <Row className="flex-nowrap">
                  <Col
                    lg={5}
                    md={6}
                    sm={6}
                    className="flex-shrink-1 custom-col"
                  >
                    <SubHeadingStyled
                      size="16px"
                      weight="500"
                      className="mb-2"
                      color="var(--white)"
                    >
                      {status === "success"
                        ? t("payment_method")
                        : t("payment")}
                    </SubHeadingStyled>
                  </Col>
                  <Col lg={7} md={6} sm={6} className="custom-col">
                    <TextStyled
                      size="16px"
                      className="mb-2"
                      color={
                        status !== "success"
                          ? "var(--dangerColor)"
                          : "var(--white)"
                      }
                    >
                      {status === "success"
                        ? t("credit_card")
                        : status === "declined"
                        ? t("declined")
                        : t("failed")}
                    </TextStyled>
                  </Col>
                </Row>
                {/* <SubHeadingStyled size="16px" weight="500" className="mb-2">
                    {t("tax")}
                  </SubHeadingStyled>
                  <SubHeadingStyled size="16px" weight="500" className="mb-2">
                    {t("total_amount")}
                  </SubHeadingStyled>
                  <SubHeadingStyled size="16px" weight="500" className="mb-2">
                    {status === "success" ? t("payment_method") : t("payment")}
                  </SubHeadingStyled>
                  <Col lg={7} md={6} sm={6}>
                    {orderData?.package_name && (
                      <TextStyled size="16px" className="mb-2">
                        {orderData?.package_name}
                      </TextStyled>
                    )}
                    {orderData?.product_name && (
                      <TextStyled size="16px" className="mb-2">
                        {orderData?.product_name}
                      </TextStyled>
                    )}
                    <TextStyled size="16px" className="mb-2">
                      {orderData?.vat_percentage} %
                    </TextStyled>
                    <TextStyled size="16px" className="mb-2">
                      {orderData?.grand_total} {t("sar")}
                    </TextStyled>
                    <TextStyled
                      size="16px"
                      className="mb-2"
                      color={status !== "success" && "var(--dangerColor)"}
                    >
                      {status === "success"
                        ? t("credit_card")
                        : status === "declined"
                        ? t("declined")
                        : t("failed")}
                    </TextStyled>
                  </Col> */}
                {orderData?.package_name && (
                  <Box className="d-flex justify-content-center mt-3">
                    {orderData?.package?.phone_number ? (
                      <Box className="d-flex flex-column align-items-center">
                        <ButtonWithIcon
                          icon={Phone}
                          label={orderData?.package?.phone_number}
                          onClick={() =>
                            WhatsApp(orderData?.package?.phone_number)
                          }
                        />
                        <Box className="mt-2">
                          <TextStyled color="var(--text)">
                            {t("trainer_personal_whatsapp_num")}
                          </TextStyled>
                        </Box>
                      </Box>
                    ) : (
                      <ContactButton
                        icon={ArrowRight2}
                        label={
                          status === "success"
                            ? t("download_your_plan")
                            : t("try_again")
                        }
                        onClick={
                          status !== "success" ? tryCheckout : handleAttachment
                        }
                      />
                    )}
                  </Box>
                )}
              </Box>
              <Box className="d-flex justify-content-center my-5">
                <Link className="home-btn" to={"/"}>
                  {t("back_to_home")} <ArrowRight2 className="mx-2" />
                </Link>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    </PaymentStatusStyled>
  );
};

export default PaymentStatus;
