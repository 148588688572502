import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TabbyPromo = ({ 
  merchantCode, 
  publicKey,
  amount,
  currency,
  description,
  buyer,
  order,
}) => {
    const {i18n: {language}} = useTranslation()
  useEffect(() => {
    // Load Tabby script
    const script = document.createElement('script');
    script.src = 'https://checkout.tabby.ai/tabby-promo.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      initializeTabby();
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initializeTabby = () => {
    if (window.TabbyPromo) {
      new window.TabbyPromo({
        selector: '#tabby-payment-method',  
        currency: currency,
        price: amount,
        // merchantCode: merchantCode,
        lang: language, // or 'ar' for Arabic
        // source: 'product',
        // publicKey: publicKey,
        payment: {
          amount: amount,
          currency: currency,
        //   description: description,
        //   buyer: buyer,
        //   order: order
        },
      });
    }
  };

  // The div where Tabby will render
  return <div id="tabby-payment-method"></div>;
};

export default TabbyPromo;