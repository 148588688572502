import React, { useEffect, useState } from "react";
import {
  ArrowRight2,
  Check,
  PagesBg,
  PkgDetailImg,
  TabbyIcon,
} from "../../components/Styled/AllImages";
import {
  Box,
  HeadingStyled,
  LabelStyled,
  LazyImage,
  SubHeadingStyled,
  TextStyled,
} from "../../components/Styled/Elements";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { PackageDetailStyled } from "./styled";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  ContactButton,
  formateNum,
  ProductCard,
} from "../../components/Styled/CustomElements";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetPackageDetailsQuery } from "../../store/services/packagesApi";
import {
  useCreateSessionMutation,
  useCreateTabbySessionMutation,
} from "../../store/services/checkoutApi";
import { useGetSettingsQuery } from "../../store/services/settingsApi";
import { PaymentBadge } from "../../components/common/PaymentBadge";
import CustomRadioButton from "../../components/common/CustomRadioButton";
import TabbyPromo from "../Checkout/TabbyPromo";
import { toast } from "react-toastify";

const PackageDetails = () => {
  const PACKAGE_ASSET_URL = process.env.REACT_APP_PACKAGE_ASSET_URL;
  const formObj = {
    customer_name: "",
    email: "",
    age: "",
    phone_number: "",
  };
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const products = useSelector((state) => state.products);
  const [formData, setFormData] = useState(formObj);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [packageDetails, setPackageDetails] = useState({});
  const [paymentOption, setPaymentOption] = useState(null);

  const [
    trigger,
    {
      data: checkoutData,
      isLoading: isCheckoutLoading,
      isError: isCheckoutError,
      refetch: refetchCheckout,
    },
  ] = useCreateSessionMutation();
  const [tabbyCheckout, { isLoading: isTabbyCheckoutLoading }] =
    useCreateTabbySessionMutation();
  const {
    data: packageData,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetPackageDetailsQuery({ id, lang: language });
  const { data: settingsData, refetch: refetchSettings } =
    useGetSettingsQuery(language);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValue(name, value);
  };

  const handleClick = (prodId) => {
    if (selectedProduct && selectedProduct === prodId) setSelectedProduct(null);
    else setSelectedProduct(prodId);
  };

  const handleBuyNow = async () => {
    if (selectedProduct) {
      navigate(`/checkout?productId=${selectedProduct}&packageId=${id}`, {
        state: formData,
      });
    } else {
      const vat = parseFloat(settingsData?.data?.vat);
      const taxVal = (vat / 100) * parseFloat(packageDetails?.price);
      const totalVal = formateNum(taxVal + parseFloat(packageDetails?.price));
      const dataObj = {
        ...formData,
        order_type: 1,
        package_id: packageDetails?.id,
        package_price: formateNum(packageDetails?.price),
        sub_total: formateNum(packageDetails?.price),
        delivery_charges: formateNum(settingsData?.data?.delivery_charges),
        grand_total: totalVal,
      };
      if (paymentOption === 1) {
        try {
          const { data, error } = await trigger({
            lang: language,
            data: dataObj,
          });

          if (error) {
            console.error("Error:", error);
          } else if (data) {
            localStorage.setItem(
              "order_info",
              JSON.stringify({
                telr_order_reference: data?.data?.telr_response?.ref,
                order_id: data?.data?.order.order_id,
              })
            );
            const checkoutUrl = data?.data?.telr_response?.url;
            if (checkoutUrl) {
              localStorage.setItem("order_data", JSON.stringify(dataObj));
              window.location.href = checkoutUrl;
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
      if (paymentOption === 2) {
        try {
          const { data, error } = await tabbyCheckout({
            lang: language,
            data: dataObj,
          });

          if (error) {
            console.error("Error:", error);
            toast.error(error?.data?.message);
          } else if (data?.code === 200) {
            localStorage.setItem(
              "order_info",
              JSON.stringify({
                tabby_order_id: data?.data?.order?.tabby_order_id,
                order_id: data?.data?.order.order_id,
              })
            );
            const checkoutUrl = data?.data?.redirect_url;
            if (checkoutUrl) {
              localStorage.setItem("order_data", JSON.stringify(dataObj));
              window.location.href = checkoutUrl;
            }
          } else if (data?.message) {
            toast.error(data?.message);
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  useEffect(() => {
    if (packageData?.data) setPackageDetails(packageData?.data);
  }, [packageData]);

  useEffect(() => {
    refetch();
  }, [refetch, language]);

  return (
    <>
      <PackageDetailStyled
        className="innerPagesBg"
        // style={{ backgroundImage: `url(${PagesBg})` }}
      >
        <Form onSubmit={handleSubmit(handleBuyNow)}>
          <Container className="common-container">
            {isLoading | isFetching ? (
              <Box className="d-flex justify-content-center">
                <Spinner animation="border" className="custom-spinner" />
              </Box>
            ) : (
              <>
                <Box className="pkg-detail_box pb-5">
                  <Row className="flex-column-reverse flex-lg-row">
                    <Col lg={6} md={12}>
                      <Box>
                        <SubHeadingStyled
                          size="36px"
                          weight="600"
                          color="var(--darkColor)"
                        >
                          {packageDetails?.name}
                        </SubHeadingStyled>
                      </Box>
                      <Box className="experience-container py-3">
                        <Box className="d-inline-block">
                          <TextStyled
                            className="duration-text px-3 m-0"
                            size="14px"
                            color="var(--white)"
                          >
                            {t("duration")}:{" "}
                            {packageDetails?.duration &&
                              parseInt(packageDetails.duration)}{" "}
                            {t("weeks")}
                          </TextStyled>
                        </Box>
                      </Box>
                      <Box>
                        <TextStyled size="16px" weight="300">
                          {packageDetails.description}
                        </TextStyled>
                      </Box>
                      <Box>
                        <HeadingStyled
                          size="24px"
                          weight="500"
                          className="mt-4 mb-3"
                        >
                          {t("features")}
                        </HeadingStyled>
                        {packageDetails?.features &&
                          packageDetails?.features.split("|").length > 0 &&
                          packageDetails?.features
                            .split("|")
                            .map((feature, i) => (
                              <Box className="d-flex">
                                <Check className="mt-1" />
                                <TextStyled
                                  size="16px"
                                  weight="300"
                                  className="mx-3"
                                >
                                  {feature}
                                </TextStyled>
                              </Box>
                            ))}
                      </Box>
                      <Box className="mt-4">
                        <Box className="field-box">
                          <LabelStyled
                            color="var(--darkColor)"
                            weight="lighter"
                          >
                            {t("full_name")}
                          </LabelStyled>
                          <input
                            className="input-field"
                            name="customer_name"
                            placeholder={t("fullNamePlaceholder")}
                            type="text"
                            value={formData.customer_name}
                            {...register("customer_name", {
                              required: true,
                              validate: (value) => {
                                const nameParts = value
                                  .trim()
                                  .split(" ")
                                  .filter(Boolean);
                                return (
                                  nameParts.length >= 2 || "invalid_name_error"
                                );
                              },
                              onChange: onChange,
                            })}
                            aria-invalid={
                              errors.customer_name ? "true" : "false"
                            }
                          />
                          {errors.customer_name && (
                            <p className="error-message" role="alert">
                              {t(errors.customer_name.message) ||
                                t("full_name_error")}
                            </p>
                          )}
                        </Box>
                        <Box className="field-box">
                          <LabelStyled
                            color="var(--darkColor)"
                            weight="lighter"
                          >
                            {t("email")}
                          </LabelStyled>
                          <input
                            className="input-field"
                            placeholder={t("emailPlaceholder")}
                            type="email"
                            value={formData.email}
                            name="email"
                            {...register("email", {
                              required: true,
                              onChange: onChange,
                            })}
                          />
                          {errors.email && (
                            <p className="error-message" role="alert">
                              {t("email_error")}
                            </p>
                          )}
                        </Box>
                        <Box className="field-box">
                          <LabelStyled
                            color="var(--darkColor)"
                            weight="lighter"
                          >
                            {t("age")}
                          </LabelStyled>
                          <input
                            className="input-field"
                            name="age"
                            value={formData.age}
                            placeholder={t("agePlaceholder")}
                            type="number"
                            {...register("age", {
                              required: true,
                              onChange: onChange,
                            })}
                            aria-invalid={errors.age ? "true" : "false"}
                          />
                          {errors.age && (
                            <p className="error-message" role="alert">
                              {t("age_error")}
                            </p>
                          )}
                        </Box>
                        <Box className="field-box">
                          <LabelStyled
                            color="var(--darkColor)"
                            weight="lighter"
                          >
                            {t("phone")}
                          </LabelStyled>
                          <input
                            className="input-field"
                            name="phone_number"
                            value={formData.phone_number}
                            placeholder={t("phonePlaceholder")}
                            type="number"
                            {...register("phone_number", {
                              required: true,
                              onChange: onChange,
                            })}
                            aria-invalid={
                              errors.phone_number ? "true" : "false"
                            }
                          />
                          {errors.phone_number && (
                            <p className="error-message" role="alert">
                              {t("phone_error")}
                            </p>
                          )}
                        </Box>
                        <Box className="d-flex gap-3 flex-wrap flex-md-nowrap align-items-center mt-3">
                          <HeadingStyled
                            size="28px"
                            weight="500"
                            color="var(--darkColor)"
                            className="text-nowrap"
                          >
                            {t("sar")} {packageDetails?.price}
                          </HeadingStyled>
                          {packageDetails?.price && (
                            // <PaymentBadge
                            //   amount={packageDetails?.price / 4}
                            //   currency="sar"
                            //   onClick={() => {}}
                            // />
                            <TabbyPromo
                              amount={packageDetails?.price}
                              currency="SAR"
                            />
                          )}
                        </Box>
                        {!selectedProduct && (
                          <Box className="mt-3">
                            <Box className="mb-2">
                              <CustomRadioButton
                                label={t("pay_with_credit_debit_card")}
                                value={1}
                                name="custom-radio"
                                checked={paymentOption === 1}
                                onChange={setPaymentOption}
                              />
                            </Box>
                            <Box>
                              <CustomRadioButton
                                label={t("pay_in_four")}
                                value={2}
                                name="custom-radio"
                                Icon={TabbyIcon}
                                checked={paymentOption === 2}
                                onChange={setPaymentOption}
                              />
                            </Box>
                          </Box>
                        )}
                        <Box className="d-flex align-items-center my-4">
                          <ContactButton
                            icon={ArrowRight2}
                            type="submit"
                            label={t("buy_now")}
                            clName="pt-0"
                            isLoading={
                              isCheckoutLoading || isTabbyCheckoutLoading
                            }
                            disabled={
                              (isCheckoutLoading ||
                                isTabbyCheckoutLoading ||
                                !paymentOption) &&
                              !selectedProduct
                            }
                          />
                        </Box>
                      </Box>
                    </Col>
                    <Col lg={6} md={12}>
                      <Box className="package-img">
                        <LazyImage
                          src={`${PACKAGE_ASSET_URL}/${packageDetails?.image}`}
                        />
                      </Box>
                    </Col>
                  </Row>
                </Box>
                {packageDetails?.products &&
                  packageDetails?.products.length > 0 && (
                    <Box className="mt-5">
                      <HeadingStyled
                        size="36px"
                        className="mb-4 recommended-header"
                        weight="500"
                      >
                        {t("recommended_suppliments_package")}
                      </HeadingStyled>
                      <Row>
                        {packageDetails?.products.map((x, i) => (
                          <Col lg={3} md={6} sm={12}>
                            <ProductCard
                              data={x}
                              t={t}
                              handleClick={handleClick}
                              selectedProduct={selectedProduct}
                              // btnDisabled={
                              //   selectedProduct && selectedProduct === x.id
                              // }
                              quantity={x.qty}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Box>
                  )}
              </>
            )}
          </Container>
        </Form>
      </PackageDetailStyled>
    </>
  );
};

export default PackageDetails;
