import React from "react";
import styled from "styled-components";
import { LabelStyled, SpanStyled } from "../Styled/Elements";

// Styled Components for Radio Button
const RadioWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
`;

const HiddenRadio = styled.input`
  display: none;
`;

const StyledRadio = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${({ checked }) => (checked ? "#12C99B" : "#ccc")};
  background: ${({ checked }) => (checked ? "#12C99B" : "white")};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: #0fa883;
  }
`;

const InnerCircle = styled.div`
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
`;

// Custom Radio Button Component
const CustomRadioButton = ({ label, value, name, checked, onChange, Icon }) => {
  return (
    <RadioWrapper>
      <HiddenRadio
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
      />
      <StyledRadio checked={checked}>{checked && <InnerCircle />}</StyledRadio>
      <SpanStyled className="d-flex gap-2">
        {Icon && <Icon width="55px" />}
        <SpanStyled htmlFor={name}>{label}</SpanStyled>
      </SpanStyled>
    </RadioWrapper>
  );
};

export default CustomRadioButton;
