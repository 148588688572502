import Index from "./Index";
import ErrorPage from "../components/Page404/ErrorPage";
import OurPackages from "../pages/OurPackages/OurPackages";
import Trainer from "../pages/Trainers/Trainers";
import TrainerDetails from "../pages/Trainers/TrainerDetails";
import PackageDetails from "../pages/OurPackages/PackageDetails";
import Layout from "../layout/Layout";
import Contact from "../pages/Contact/Contact";
import PaymentStatus from "../pages/Payment/PaymentStatus";
import Products from "../pages/Products/Products";
import ProductDetails from "../pages/Products/ProductDetails";
import Checkout from "../pages/Checkout/Checkout";
import Terms from "../pages/Terms/Terms";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import TabbyPaymentStatus from "../pages/Payment/TabbyPaymentStatus";

export const privateRoute = [
  {
    path: "/",
    element: Index,
  },
  {
    path: "/",
    element: Layout,
    children: [
      {
        path: "/our-packages",
        element: OurPackages,
      },
      {
        path: "/products",
        element: Products,
      },
      {
        path: "/product-details/:id",
        element: ProductDetails,
      },
      {
        path: "/trainers",
        element: Trainer,
      },
      {
        path: "/trainer-details/:id",
        element: TrainerDetails,
      },
      {
        path: "/package-details/:id",
        element: PackageDetails,
      },
      {
        path: "/contact-us",
        element: Contact,
      },
      {
        path: "/checkout",
        element: Checkout,
      },
      {
        path: "/payment-status/:status",
        element: PaymentStatus,
      },
      {
        path: "/payment-status-info/:status",
        element: TabbyPaymentStatus,
      },
      {
        path: "/term-conditions",
        element: Terms,
      },
      {
        path: "/privacy-policy",
        element: PrivacyPolicy,
      },
    ],
  },
  {
    path: "*",
    element: ErrorPage,
  },
];
